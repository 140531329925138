<script setup>
    import PrimevueProgressSpinner from 'primevue/progressspinner';

    // Define props
    const props = defineProps({
        // Url to redirect when click on card
        url: {
            type: String,
        },
        pt: {
            type: Object
        },
        loading: {
            type: Boolean,
            default: false,
        }
    });
</script>

<template>
    <div class="card" :class="{clickable: url != null, loading: props.loading}" v-bind="props.pt?.root">
        <Redirect v-if="url" :to="url" />

        <section v-if="$slots.header" class="card__header" v-bind="props?.pt?.header">
            <slot name="header" />
        </section>

        <section class="card__body" v-bind="props?.pt?.body">
            <slot />
            <slot name="body" />
        </section>

        <slot name="root" />

        <section v-if="$slots.footer" class="card__footer">
            <slot name="footer" />
        </section>

        <div v-if="props.loading" class="card__loader">
            <PrimevueProgressSpinner />
        </div>
    </div>
</template>
